import React, { useState, useEffect } from "react";
// Chakra imports
import {
  Container,
  Stack,
  Flex,
  Box,
  Heading,
  Text,
  Button,
  Image,
  Icon,
  createIcon,
  useColorModeValue,
  Progress,
  Avatar,
  Spinner,
  Center,
  Show,
  Hide,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Divider,
  useClipboard,
  Input,
} from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import { auth, db } from "../../firebase";
import { query, getDocs, collection, where } from "firebase/firestore";
import { useParams } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { getIdToken } from "firebase/auth";

function Campaign() {
  const history = useHistory();
  const { id } = useParams();
  const [show, setShow] = React.useState(false);
  const [data, setData] = useState([]);
  const [userData, setUserData] = useState([]);
  const [progressPercentage, setProgressPercentage] = useState(1);
  const [firebaseToken, setFirebaseToken] = useState("");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { onCopy, value, setValue, hasCopied } = useClipboard(
    `https://priorifi.com/c/${id}`
  );

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchCampaign();
    getFirebaseToken();
  }, []);

  const getFirebaseToken = async () => {
    const token = await getIdToken(user, true);
    setFirebaseToken(token);
    console.log(token);
  };

  const fetchCampaign = async () => {
    const docRef = doc(db, "campaigns", id);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      setData(docSnap.data());
      calculatePercentage(
        docSnap.data().amount_received,
        docSnap.data().amount_targeted
        );
      fetchUser(docSnap.data().user_id);
    } else {
      setData(undefined);
      console.log("No campaign document!");
    }
  };

  const fetchUser = async (user_id) => {
    const docRef = doc(db, "users", user_id);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      setUserData(docSnap.data());
    } else {
      setUserData(undefined);
      console.log("No user document!");
    }
    setLoading(false);
  };

  const renderAlumni = () => {
    if (userData.alumni == null || userData.alumni == "") {
      return <Text></Text>
    } else {
      return <Text color={"gray.500"}>Alumni, {userData.alumni}</Text>
    }
  }

  const PlayIcon = createIcon({
    displayName: "PlayIcon",
    viewBox: "0 0 58 58",
    d:
      "M28.9999 0.562988C13.3196 0.562988 0.562378 13.3202 0.562378 29.0005C0.562378 44.6808 13.3196 57.438 28.9999 57.438C44.6801 57.438 57.4374 44.6808 57.4374 29.0005C57.4374 13.3202 44.6801 0.562988 28.9999 0.562988ZM39.2223 30.272L23.5749 39.7247C23.3506 39.8591 23.0946 39.9314 22.8332 39.9342C22.5717 39.9369 22.3142 39.8701 22.0871 39.7406C21.86 39.611 21.6715 39.4234 21.5408 39.1969C21.4102 38.9705 21.3421 38.7133 21.3436 38.4519V19.5491C21.3421 19.2877 21.4102 19.0305 21.5408 18.8041C21.6715 18.5776 21.86 18.3899 22.0871 18.2604C22.3142 18.1308 22.5717 18.064 22.8332 18.0668C23.0946 18.0696 23.3506 18.1419 23.5749 18.2763L39.2223 27.729C39.4404 27.8619 39.6207 28.0486 39.7458 28.2713C39.8709 28.494 39.9366 28.7451 39.9366 29.0005C39.9366 29.2559 39.8709 29.507 39.7458 29.7297C39.6207 29.9523 39.4404 30.1391 39.2223 30.272Z",
  });

  const Blob = (props) => {
    return (
      <Icon
        width={"100%"}
        viewBox="0 0 578 440"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M239.184 439.443c-55.13-5.419-110.241-21.365-151.074-58.767C42.307 338.722-7.478 282.729.938 221.217c8.433-61.644 78.896-91.048 126.871-130.712 34.337-28.388 70.198-51.348 112.004-66.78C282.34 8.024 325.382-3.369 370.518.904c54.019 5.115 112.774 10.886 150.881 49.482 39.916 40.427 49.421 100.753 53.385 157.402 4.13 59.015 11.255 128.44-30.444 170.44-41.383 41.683-111.6 19.106-169.213 30.663-46.68 9.364-88.56 35.21-135.943 30.551z"
          fill="#1a202c"
        />
      </Icon>
    );
  };

  const calculatePercentage = (received, targeted) => {
    if (received <= 1) {
      setProgressPercentage(1)
    } else {
      let percentage = (received / targeted) * 100
      setProgressPercentage(percentage)
    }
  }

  const renderCampaign = () => {
    if (loading) {
      return (
        <Center mt={200} mb={200}>
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color="#0099d3"
            size="xl"
          />
        </Center>
      );
    } else {
      return (
        <Stack
          align={"center"}
          spacing={{ base: 8, md: 10 }}
          py={{ base: 20, md: 28 }}
          direction={{ base: "column", md: "row" }}
        >
          <Stack flex={1} spacing={{ base: 5, md: 10 }}>
            <Show below={"md"}>
              <Image
                alt={"Hero Image"}
                fit={"cover"}
                align={"center"}
                w={"100%"}
                h={200}
                src={data.photo}
                mb={5}
              />
            </Show>
            <Heading
              lineHeight={1.1}
              fontWeight={600}
              fontSize={{ base: "3xl", sm: "4xl", lg: "6xl" }}
            >
              <Text as={"span"} color={"#0099d3"}>
                {data.title}
              </Text>
              <Stack mt={6} direction={"row"} spacing={4} align={"center"}>
                <Avatar src={userData.profilePic} />
                <Stack direction={"column"} spacing={0} fontSize={"sm"}>
                  <Text color={"silver"} fontWeight={600}>
                    Created by {userData.fullname}
                  </Text>
                  {renderAlumni()}
                </Stack>
              </Stack>
            </Heading>
            <Text fontSize="xl" color={"silver"}>
              ${data.amount_received.toLocaleString()} raised of ${data.amount_targeted.toLocaleString()} goal
            </Text>
            <Progress value={progressPercentage} size="xs" colorScheme="blue" />
            <Text color={"silver"}>{data.description}</Text>
            <Stack
              spacing={{ base: 4, sm: 6 }}
              direction={{ base: "column", sm: "row" }}
            >
              <Button
                rounded={"full"}
                size={"lg"}
                fontWeight={"bold"}
                textColor={"black"}
                px={6}
                w={40}
                bg={"#0099d3"}
                onClick={() =>
                  history.push(`${window.location.pathname}/donate`, {
                    campaignData: data,
                    userData: userData,
                  })
                }
                _hover={{ bg: "#52c5f0" }}
              >
                Donate now
              </Button>

              <Button
                onClick={onOpen}
                rounded={"full"}
                size={"lg"}
                fontWeight={"bold"}
                textColor={"#0099d3"}
                px={6}
                borderWidth={1}
                borderColor={"#0099d3"}
                w={40}
                bg={"transparent"}
                _hover={{ bg: "#0099d3", color: "black" }}
              >
                Share
              </Button>
            </Stack>
          </Stack>
          <Hide below={"md"}>
            <Flex
              flex={1}
              justify={"center"}
              align={"center"}
              position={"relative"}
              w={"full"}
            >
              <Blob
                w={"150%"}
                h={"150%"}
                position={"absolute"}
                top={"-20%"}
                left={0}
                zIndex={-1}
                color={useColorModeValue("red.50", "red.400")}
              />
              <Box
                position={"relative"}
                height={"300px"}
                rounded={"2xl"}
                boxShadow={"2xl"}
                width={"full"}
                overflow={"hidden"}
              >
                <Image
                  alt={"Hero Image"}
                  fit={"cover"}
                  align={"center"}
                  w={"100%"}
                  h={"100%"}
                  src={data.photo}
                />
              </Box>
            </Flex>
          </Hide>
          <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent bg={"#1a202c"}>
              <ModalHeader color={"silver"} mt={10}>
                Help by sharing
              </ModalHeader>
              <ModalHeader color={"gray.500"} fontWeight={400} fontSize={"md"}>
                Campaigns shared on social media raise up to 5x more.
              </ModalHeader>
              <ModalCloseButton color={"silver"} />
              <Divider mb={2} />
              <ModalBody>
                <Flex mb={2}>
                  <Input value={value} mr={2} fontSize={"sm"} color={"#0099d3"} />
                  <Button onClick={onCopy}>
                    {hasCopied ? "Copied!" : "Copy"}
                  </Button>
                </Flex>
                <Divider mt={5} />

                <Stack mt={5} mb={5}>
                  <Text color={"gray.500"}>
                    Paste this campaign link anywhere!
                  </Text>
                </Stack>
              </ModalBody>
            </ModalContent>
          </Modal>
        </Stack>
      );
    }
  };

  return (
    <Container maxW={"7xl"} mt={5}>
      {renderCampaign()}
    </Container>
  );
}

export default Campaign;
