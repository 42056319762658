// import
import Dashboard from "views/Dashboard/Dashboard";
import Members from "views/Dashboard/Members";
import Billing from "views/Dashboard/Billing";
import Events from "views/Dashboard/Events";
import Login from "views/Auth/Login.js";
import Register from "views/Auth/Register.js";
import Plans from "views/Dashboard/Plans";
import Member from "views/Dashboard/Members/Member";
import Membership from "views/Dashboard/Members/Membership";
import Campaign from "views/Campaign/Campaign";
import Donate from "views/Campaign/Donate";
import Confirmation from "views/Campaign/Confirmation";

import {
  HomeIcon,
  StatsIcon,
  CreditIcon,
  PersonIcon,
  DocumentIcon,
  RocketIcon,
  SupportIcon,
} from "components/Icons/Icons";
import { EditIcon } from '@chakra-ui/icons'


var dashRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: <StatsIcon color="inherit" />,
    component: Dashboard,
    layout: "/admin",
  },
  {
    path: "/members",
    name: "Members",
    icon: <PersonIcon color="inherit" />,
    component: Members,
    layout: "/admin",
  },
  {
    path: "/member/:id",
    name: "Member",
    icon: <PersonIcon color="inherit" />,
    component: Member,
    layout: "/admin",
    ignoreSidebar: true
  },
  {
    path: "/membership/:id",
    name: "Membership",
    icon: <PersonIcon color="inherit" />,
    component: Membership,
    layout: "/admin",
    ignoreSidebar: true
  },
  {
    path: "/plans",
    name: "Plans",
    icon: <EditIcon color="inherit" height='14px' />,
    component: Plans,
    layout: "/admin",
  },
  {
    path: "/events",
    name: "Events",
    icon: <DocumentIcon color="inherit" />,
    component: Events,
    layout: "/admin",
  },
  {
    path: "/billing",
    name: "Billing",
    icon: <CreditIcon color="inherit" />,
    component: Billing,
    layout: "/admin",
  },
  {
    name: "ACCOUNT PAGES",
    category: "account",
    state: "pageCollapse",
    views: [
      {
        path: "/login",
        name: "Login",
        icon: <DocumentIcon color="inherit" />,
        component: Login,
        layout: "/auth",
      },
      {
        path: "/register",
        name: "Register",
        icon: <RocketIcon color="inherit" />,
        secondaryNavbar: true,
        component: Register,
        layout: "/auth",
      },
      {
        path: "/login",
        name: "Login",
        icon: <RocketIcon color="inherit" />,
        secondaryNavbar: true,
        component: Login,
        layout: "/auth",
      },
      {
        path: "/register",
        name: "Register",
        icon: <RocketIcon color="inherit" />,
        secondaryNavbar: true,
        component: Register,
        layout: "/auth",
      },
    ],
  },
  {
    name: "CAMPAIGN PAGES",
    category: "campaign",
    state: "pageCollapse",
    views: [
      {
        path: "/:id/donate/confirmation",
        name: "Confirmation",
        icon: <DocumentIcon color="inherit" />,
        component: Confirmation,
        layout: "/c",
      },
      {
        path: "/:id/donate",
        name: "Donate",
        icon: <DocumentIcon color="inherit" />,
        component: Donate,
        layout: "/c",
      },
      {
        path: "/:id",
        name: "Campaign",
        icon: <DocumentIcon color="inherit" />,
        component: Campaign,
        layout: "/c",
      }
    ],
  },
];
export default dashRoutes;
