import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store, persistor } from './configureStore';
import { PersistGate } from 'redux-persist/integration/react';
import AdminLayout from 'layouts/Admin.js';
import AuthLayout from 'layouts/Auth.js';
import CampaignLayout from 'layouts/Campaign.js';

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <BrowserRouter>
        <Switch>
          <Route path={'/admin'} component={AdminLayout} />
          <Route path={`/auth`} component={AuthLayout} />
          <Route path={`/c`} component={CampaignLayout} />
          {/* <Redirect from="/" to="/auth/login" /> */}
        </Switch>
      </BrowserRouter>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);
