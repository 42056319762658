import React, { useState, useEffect } from 'react';
// Chakra imports
import {
  Box,
  Flex,
  Button,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Switch,
  Text,
  useColorModeValue,
  InputGroup,
  InputRightElement,
} from '@chakra-ui/react';
// Assets
import LoginImage from 'assets/img/logInImage2.png';
import { NavLink, useHistory } from 'react-router-dom';
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import { auth, db } from '../../firebase';
import { useAuthState } from 'react-firebase-hooks/auth';
import {
  signInWithEmailAndPassword,
} from 'firebase/auth';
import {
  query,
  getDocs,
  collection,
  where,
} from 'firebase/firestore';
import { useDispatch } from 'react-redux';
import { login } from '../../reducers';

function Login() {
  const dispatch = useDispatch();
  const history = useHistory();
  // Chakra color mode
  const titleColor = useColorModeValue('teal.300', 'teal.200');
  const textColor = useColorModeValue('white', 'white');
  const [show, setShow] = React.useState(false);
  const handleClick = () => setShow(!show);
  const [dispatching, setDispatching] = useState(true);

  const [email, setEmail] = useState('');
  const handleEmailChange = event => {
    setEmail(event.target.value);
  };

  const [password, setPassword] = useState('');
  const handlePasswordChange = event => {
    setPassword(event.target.value);
  };

  const logInWithEmailAndPassword = async () => {
    if (!email) alert('Please enter your email');
    if (!password) alert('Please enter your password');
    try {
      const res = await signInWithEmailAndPassword(auth, email, password);
      const user = res.user;
      const q = query(collection(db, 'clients'), where('uid', '==', user.uid));
      const docs = await getDocs(q);
      dispatch(login(docs.docs[0].data()));
      setDispatching(false)
    } catch (err) {
      console.error(err);
      alert(err.message);
    }
  };

  const [user, loading] = useAuthState(auth);

  useEffect(() => {
    if (loading || dispatching) return;
    if (user) navigate();
  }, [user, loading, dispatching, navigate]);

  const navigate = () => {
    history.push('/admin/dashboard');
  };

  return (
    <Flex position="relative" mb="40px">
      <Flex
        h={{ sm: 'initial', md: '75vh', lg: '85vh' }}
        w="100%"
        maxW="1044px"
        mx="auto"
        justifyContent="space-between"
        mb="30px"
        pt={{ sm: '100px', md: '0px' }}
      >
        <Flex
          alignItems="center"
          justifyContent="start"
          style={{ userSelect: 'none' }}
          w={{ base: '100%', md: '50%', lg: '42%' }}
        >
          <Flex
            direction="column"
            w="100%"
            background="transparent"
            p="48px"
            mt={{ md: '150px', lg: '80px' }}
          >
            <Heading color={titleColor} fontSize="32px" mb="10px">
              Welcome Back
            </Heading>
            <Text
              mb="36px"
              ms="4px"
              color={textColor}
              fontWeight="bold"
              fontSize="15px"
            >
              Enter your email and password to sign in
            </Text>
            <FormControl>
              <FormLabel color="white" ms="4px" fontSize="sm" fontWeight="bold">
                Email
              </FormLabel>
              <Input
                mb="24px"
                fontSize="md"
                type="text"
                value={email}
                onChange={handleEmailChange}
                color="white"
                placeholder="E-mail Address"
                size="lg"
                borderRadius={30}
                borderColor="silver"
                fontWeight="bold"
                _placeholder={{
                  fontWeight: 'bold',
                  color: 'silver',
                  opacity: 0.5,
                }}
                _hover={{ borderColor: 'teal.300' }}
                focusBorderColor="teal.300"
              />
              <FormLabel color="white" ms="4px" fontSize="sm" fontWeight="bold">
                Password
              </FormLabel>
              <InputGroup>
                <Input
                  mb="36px"
                  fontSize="md"
                  placeholder="Password"
                  type={show ? 'text' : 'password'}
                  size="lg"
                  color="white"
                  borderRadius={30}
                  value={password}
                  onChange={handlePasswordChange}
                  borderColor="silver"
                  fontWeight="bold"
                  _placeholder={{
                    fontWeight: 'bold',
                    color: 'silver',
                    opacity: 0.5,
                  }}
                  _hover={{ borderColor: 'teal.300' }}
                  focusBorderColor="teal.300"
                />
                <InputRightElement width="4.5rem">
                  <Button
                    marginTop="6px"
                    h="1.75rem"
                    size="sm"
                    onClick={handleClick}
                  >
                    {show ? <ViewIcon /> : <ViewOffIcon />}
                  </Button>
                </InputRightElement>
              </InputGroup>
              <FormControl display="flex" alignItems="center">
                <Switch id="remember-login" colorScheme="teal" me="10px" />
                <FormLabel
                  htmlFor="remember-login"
                  mb="0"
                  ms="1"
                  color="white"
                  fontWeight="normal"
                >
                  Remember me
                </FormLabel>
              </FormControl>
              <Button
                fontSize="15px"
                type="submit"
                w="100%"
                h="45"
                mb="20px"
                mt="20px"
                bg="white"
                onClick={() => logInWithEmailAndPassword()}
                color="black"
                fontWeight="bold"
                borderRadius={30}
                _hover={{ bg: 'silver' }}
              >
                Sign In
              </Button>
            </FormControl>
            <Flex
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              maxW="100%"
              mt="0px"
            >
              <Text color={textColor} fontWeight="medium">
                Don't have an account?
                <NavLink
                  to="/auth/register"
                  style={{ color: '#81e6d9', marginLeft: '5px' }}
                  as="span"
                  ms="5px"
                  fontWeight="bold"
                >
                  Start your free trial
                </NavLink>
              </Text>
            </Flex>
          </Flex>
        </Flex>
        <Box
          display={{ base: 'none', md: 'block' }}
          overflowX="hidden"
          h="100%"
          w="40vw"
          position="absolute"
          right="0px"
        >
          <Box
            // bgImage={LoginImage}
            w="100%"
            h="100%"
            bgSize="cover"
            bgPosition="50%"
            position="absolute"
            borderBottomLeftRadius="20px"
          ></Box>
        </Box>
      </Flex>
    </Flex>
  );
}

export default Login;
