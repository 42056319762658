import React, { useState, useEffect } from "react";
// Chakra imports
import {
  Heading,
  Box,
  Center,
  Image,
  Text,
  Stack,
  Container,
  NumberInput,
  NumberInputField,
  NumberIncrementStepper,
  NumberInputStepper,
  NumberDecrementStepper,
  FormControl,
  FormLabel,
  Input,
  HStack,
  Checkbox,
  Tooltip,
  VStack,
  Icon,
  Divider,
  Hide,
} from "@chakra-ui/react";
import { InfoOutlineIcon, CheckIcon } from "@chakra-ui/icons";
import { useHistory } from "react-router-dom";
import { CreditCard, PaymentForm } from "react-square-web-payments-sdk";
import { v4 as uuidv4 } from "uuid";
import { auth, db } from "../../firebase";
import {
  collection,
  addDoc,
  updateDoc,
  doc
} from "firebase/firestore";
import { useParams } from "react-router-dom";
import { getIdToken } from "firebase/auth";

function Donate(props) {
  const history = useHistory();
  const { id } = useParams();
  const [campaignData, setCampaignData] = useState(
    props.location.state.campaignData
  );
  const [userData, setUserData] = useState(props.location.state.userData);
  const [firebaseToken, setFirebaseToken] = useState("");
  const [loading, setLoading] = useState(true);
  const [donationAmount, setDonationAmount] = React.useState(5);

  const [isAnonymous, setIsAnonymous] = React.useState(false);
  const handleIsChecked = () => {
    isAnonymous == false ? setIsAnonymous(true) : setIsAnonymous(false);
  };

  const [donorFirstName, setDonorFirstName] = useState("");
  const handleFirstNameChange = (event) => {
    setDonorFirstName(event.target.value);
    if (donorFirstName && donorLastName && donorEmail != "") {
      setLoading(false);
    } else {
      setLoading(true);
    }
  };

  const [donorLastName, setDonorLastName] = useState("");
  const handleLastNameChange = (event) => {
    setDonorLastName(event.target.value);
    if (donorFirstName && donorLastName && donorEmail != "") {
      setLoading(false);
    } else {
      setLoading(true);
    }
  };

  const [donorEmail, setDonorEmail] = useState("");
  const handleEmailChange = (event) => {
    setDonorEmail(event.target.value);
    if (donorFirstName && donorLastName && donorEmail != "") {
      setLoading(false);
    } else {
      setLoading(true);
    }
  };

  const format = (val) => `$` + val;
  const parse = (val) => val.replace(/^\$/, "");
  const devUrl = 'http://localhost:5002'
  const prodUrl = 'https://priorifi.com'

  useEffect(() => {
    console.log(id);
  }, []);

  const createDonation = async (
    priorifiFee,
    processingFee,
    paymentDetails,
    conId
  ) => {
    try {
      const guid = "DON-" + uuidv4();
      const date = new Date();
      const amountAfterFees = (Number(parseInt(donationAmount + "00")) - Number(priorifiFee) - Number(processingFee))
      const doc = await addDoc(collection(db, `donations`), {
        donorName: donorFirstName + " " + donorLastName,
        donorEmail: donorEmail,
        donationAmount: parseInt(donationAmount + "00"),
        donationAmountAfterFees: amountAfterFees,
        priorifiFeeAmount: priorifiFee,
        processingFeeAmount: processingFee,
        campaignGuid: campaignData.guid,
        campaignId: id,
        created_timestamp: Date.now(),
        created_readable: date.toGMTString(),
        paymentDetails: paymentDetails,
        isAnonymous: isAnonymous,
        guid: guid,
        confirmation: conId,
        recipient_user_id: campaignData.user_id
      });
    } catch (err) {
      console.error(err);
      alert(err.message);
    }
  };

  const updateUserBalance = async (priorifiFee, processingFee) => {
    const amount = Number(userData.donation_balance) + (Number(donationAmount) - Number(priorifiFee / 100) - Number(processingFee / 100))
    const docRef = await doc(db, 'users', userData.id);
    const data = {
      donation_balance: Number(amount.toFixed(2))
    };
    updateDoc(docRef, data)
      .then(() => {
        console.log('User balance has been updated successfully.');
      })
      .catch(error => {
        console.log(error);
      });
  }

  const updateCampaign = async () => {
    const docRef = await doc(db, 'campaigns', id);
    const data = {
      amount_received: Number(donationAmount) + Number(campaignData.amount_received),
      amount_received_count: 1 + Number(campaignData.amount_received_count)
    };
    updateDoc(docRef, data)
      .then(() => {
        console.log('Campaign amount has been updated successfully.');
      })
      .catch(error => {
        console.log(error);
      });
  };

  const sendConfirmationEmails = async (
    conId,
    last4
  ) => {
    try {
      let params = `?email=${donorEmail}&confirmation=${conId}&last4=${last4}&amount=${donationAmount}`
      fetch(`${prodUrl}/api/send-email${params}`, {
        method: "POST",
      })

      let email_params = `?user_email=${userData.email}`
      fetch(`${prodUrl}/api/send-email-notification${email_params}`, {
        method: "POST",
      })
    } catch (err) {
      console.error(err);
      alert(err.message);
    }
  };

  return (
    <Container maxW={"7xl"} mt={5}>
      <Center py={6} mt={20}>
        <Box
          maxW={"600px"}
          w={"full"}
          boxShadow={"2xl"}
          rounded={"xl"}
          overflow={"hidden"}
          bg={"#1a202c"}
        >
          <Stack m={10}>
            <Stack spacing={4}>
              <Heading
                lineHeight={1.1}
                fontWeight={600}
                mb={5}
                fontSize={{ base: "3xl", sm: "4xl", lg: "6xl" }}
              >
                <Stack direction={"row"} spacing={4} align={"center"}>
                  <Hide below="md">
                    <Image
                      rounded={"lg"}
                      height={85}
                      width={85}
                      objectFit={"cover"}
                      src={campaignData.photo}
                      alt="#"
                    />
                  </Hide>
                  <Stack spacing={3}>
                    <Text fontSize={"md"} color={"silver"} fontWeight={600}>
                      You're contributing to:{" "}
                      <Text mt={1} fontSize={"lg"} fontWeight={"bold"}>
                        {campaignData.title}
                      </Text>
                    </Text>
                    <Text fontSize={"md"} color={"gray.500"}>
                      Which will help lower {userData.fullname}'s student loan
                      debt
                    </Text>
                  </Stack>
                </Stack>
              </Heading>
              <Text fontWeight={"bold"} fontSize="md" color={"silver"}>
                Enter your donation
              </Text>
              <NumberInput
                onChange={(valueString) =>
                  setDonationAmount(parse(valueString))
                }
                value={format(donationAmount)}
                min={5}
                max={50000}
                step={5}
                color={"silver"}
                focusBorderColor={"#0099d3"}
                size={"lg"}
              >
                <NumberInputField />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
              <HStack>
                <FormControl id="firstName" isRequired>
                  <FormLabel requiredIndicator={" *"} color={"silver"}>
                    First Name
                  </FormLabel>
                  <Input
                    focusBorderColor={"#0099d3"}
                    color={"white"}
                    type="text"
                    value={donorFirstName}
                    onChange={handleFirstNameChange}
                  />
                </FormControl>
                <FormControl id="lastName" isRequired>
                  <FormLabel requiredIndicator={" *"} color={"silver"}>
                    Last Name
                  </FormLabel>
                  <Input
                    focusBorderColor={"#0099d3"}
                    color={"white"}
                    type="text"
                    value={donorLastName}
                    onChange={handleLastNameChange}
                  />
                </FormControl>
              </HStack>
              <FormControl id="email" isRequired>
                <FormLabel requiredIndicator={" *"} color={"silver"}>
                  Email address
                </FormLabel>
                <Input
                  focusBorderColor={"#0099d3"}
                  color={"white"}
                  type="email"
                  value={donorEmail}
                  onChange={handleEmailChange}
                />
              </FormControl>
              <FormControl isRequired>
                <Checkbox
                  isChecked={isAnonymous}
                  onChange={handleIsChecked}
                  colorScheme={"purple"}
                  color={"silver"}
                  mb={5}
                >
                  Don't display my name publicly on the campaign.{" "}
                  <Tooltip
                    label="Your name will only be visible to the organizer, any team members and the beneficiary"
                    fontSize="sm"
                  >
                    <InfoOutlineIcon />
                  </Tooltip>
                </Checkbox>
                {/* <FormLabel requiredIndicator={" *"} color={"silver"}>
                    Country
                  </FormLabel>
                  <Select
                    focusBorderColor={"#0099d3"}
                    color={"white"}
                    mb={5}
                    placeholder="Select country"
                  >
                    <option>United States</option>
                    <option>Canada</option>
                    <option>Mexico</option>
                  </Select> */}
              </FormControl>
            </Stack>
            <PaymentForm
              applicationId="sandbox-sq0idb-LHQLvhfh2yyuqXbwRMf4aA"
              cardTokenizeResponseReceived={async (token, buyer) => {
                console.info({ token, buyer });
                let amount = donationAmount;
                let pFee = parseInt(amount) * 0.029 + 0.3;
                let processFee = (Math.round(pFee * 100) / 100).toFixed(2);
                let processFeeCents = processFee * 100;
                let priorifi_fee = Math.round(
                  (parseInt(amount + "00") - processFeeCents) * 0.05
                );
                let body = JSON.stringify({
                  sourceId: token.token,
                  amountMoney: {
                    amount: amount + "00",
                    currency: "USD",
                  },
                  appFeeMoney: {
                    amount: priorifi_fee,
                    currency: "USD",
                  },
                  location_id: "L0EE71BSAJNW6",
                });
                const paymentResponse = await fetch(
                  `${prodUrl}/api/pay`,
                  {
                    method: "POST",
                    headers: {
                      "Content-Type": "application/json",
                    },
                    body,
                  }
                );

                if (paymentResponse.ok) {
                  const res = await paymentResponse.json();
                  const conId = res.result.payment.id;
                  createDonation(
                    priorifi_fee,
                    processFeeCents,
                    token.details,
                    conId
                  );
                  sendConfirmationEmails(
                    conId,
                    token.details.card.last4
                  );
                  updateCampaign();
                  updateUserBalance(priorifi_fee, processFeeCents);
                  history.push(`${window.location.pathname}/confirmation`, {
                    confirmation: conId,
                    shareUrl: `https://priorifi.com/c/${id}`
                  });
                }
              }}
              // createVerificationDetails={() => ({
              //   amount: donationAmount,
              //   billingContact: {
              //     addressLines: ["123 Main Street", "Apartment 1"],
              //     familyName: "Doe",
              //     givenName: "John",
              //     countryCode: "GB",
              //     city: "London",
              //   },
              //   currencyCode: "GBP",
              //   intent: "CHARGE",
              // })}
              locationId="L0EE71BSAJNW6"
            >
              <CreditCard
                style={{
                  input: {
                    fontSize: "16px",
                    backgroundColor: "#1a202c",
                    color: "white",
                  },
                  ".input-container.is-focus": {
                    borderColor: "#0099d3",
                  },
                  "input.is-error": {
                    color: "#fc8180",
                  },
                  "input::placeholder": {
                    color: "silver",
                  },
                  ".message-text": {
                    color: "silver",
                  },
                  ".message-icon": {
                    color: "silver",
                  },
                  ".message-icon.is-error": {
                    color: "#fc8180",
                  },
                  ".message-text.is-error": {
                    color: "#fc8180",
                  },
                  ".input-container.is-error": {
                    borderColor: "#fc8180",
                  },
                }}
                buttonProps={{
                  isLoading: loading,
                  css: {
                    backgroundColor: "#0099d3",
                    fontSize: "14px",
                    color: "black",
                    "&:hover": {
                      backgroundColor: "#52c5f0",
                    },
                    "&:disabled": {
                      backgroundColor: "#0099d3",
                    },
                    marginBottom: 12,
                  },
                }}
              >
                Donate now
              </CreditCard>
            </PaymentForm>

            <Text color={"silver"} fontSize={14}>
              By continuing, you agree with Priorifi terms and privacy notice.
              Learn more about pricing and fees.
            </Text>

            <Divider mt={5} />

            <HStack mt={5} align={"top"}>
              <Box color={"green.400"} px={2}>
                <Icon as={CheckIcon} />
              </Box>
              <VStack align={"start"}>
                <Text color={"silver"} fontSize={14} fontWeight={600}>
                  Priorifi protects your donation
                </Text>
                <Text color={"gray.500"} fontSize={14}>
                  We guarantee you a full refund for up to a year in the rare
                  case that fraud occurs. See our Priorifi dontation guarantee.
                </Text>
              </VStack>
            </HStack>
          </Stack>
        </Box>
      </Center>
    </Container>
  );
}

export default Donate;
