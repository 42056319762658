import React, { useState, useEffect } from "react";
// Chakra imports
import {
  Heading,
  Box,
  Center,
  Stack,
  Button,
  Container,
  Icon,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Text,
  Divider,
  useClipboard,
  Flex,
  Input,
} from "@chakra-ui/react";
import { CheckCircleIcon } from "@chakra-ui/icons";
// Assets

function Confirmation(props) {
  const [confirmation, setConfirmation] = useState(
    props.location.state.confirmation
  );
  const [shareUrl, setShareUrl] = useState(props.location.state.shareUrl);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { onCopy, value, setValue, hasCopied } = useClipboard(shareUrl);

  return (
    <Container maxW={"7xl"} mt={38}>
      <Center>
        <Box
          mt={20}
          maxW={"600px"}
          w={"full"}
          boxShadow={"2xl"}
          rounded={"xl"}
          overflow={"hidden"}
          bg={"#1a202c"}
        >
          <Stack spacing={10} align={"center"}>
            <Heading
              mt={10}
              color={"silver"}
              fontSize={"2xl"}
              fontWeight={500}
              fontFamily={"body"}
            >
              Thank you for your donation!
            </Heading>
            <Icon boxSize={100} color={"#0099d3"} as={CheckCircleIcon} />
          </Stack>

          <Stack spacing={0} align={"center"}>
            <Heading
              m={10}
              align={"center"}
              color={"gray.500"}
              fontSize={"md"}
              fontWeight={500}
              fontFamily={"body"}
            >
              Your confirmation id is {confirmation}. You'll receive an email
              confirmation for your records within the next 24 hours.
            </Heading>
          </Stack>

          <Center mb={5}>
            <Button
              onClick={onOpen}
              w={"55%"}
              mt={8}
              bg={"#0099d3"}
              color={"black"}
              rounded={"md"}
              _hover={{
                transform: "translateY(-2px)",
                boxShadow: "lg",
              }}
            >
              Share
            </Button>
          </Center>
        </Box>
      </Center>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent bg={"#1a202c"}>
          <ModalHeader color={"silver"} mt={10}>
            Help by sharing
          </ModalHeader>
          <ModalHeader color={"gray.500"} fontWeight={400} fontSize={"md"}>
            Campaigns shared on social media raise up to 5x more.
          </ModalHeader>
          <ModalCloseButton color={"silver"} />
          <Divider mb={2} />
          <ModalBody>
            <Flex mb={2}>
              <Input value={value} mr={2} fontSize={"sm"} color={"white"} />
              <Button onClick={onCopy}>{hasCopied ? "Copied!" : "Copy"}</Button>
            </Flex>
            <Divider mt={5} />

            <Stack mt={5} mb={5}>
              <Text color={"gray.500"}>Paste this campaign link anywhere!</Text>
            </Stack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Container>
  );
}

export default Confirmation;
