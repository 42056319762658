// Chakra imports
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Link,
  Switch,
  Text,
  useColorModeValue,
  Select,
  Checkbox,
  Stack,
  InputGroup,
  InputRightElement,
} from '@chakra-ui/react';
// Assets
import BgRegister from 'assets/img/BgRegister.png';
import React, { useState, useEffect } from 'react';
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import { useHistory } from 'react-router-dom';
import {
  auth,
  db
} from '../../firebase';
import {
  createUserWithEmailAndPassword,
} from 'firebase/auth';
import {
  collection,
  addDoc,
} from 'firebase/firestore';
import { v4 as uuidv4 } from 'uuid';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useDispatch } from 'react-redux';
import { login } from '../../reducers';

function Register() {
  const dispatch = useDispatch();
  const history = useHistory();
  const titleColor = useColorModeValue('teal.300', 'teal.200');
  const textColor = useColorModeValue('white', 'white');
  const bgColor = useColorModeValue('transparent', 'transparent');
  const bgIcons = useColorModeValue('teal.200', 'rgba(255, 255, 255, 0.5)');
  const [show, setShow] = React.useState(false);
  const handleClick = () => setShow(!show);
  const [dispatching, setDispatching] = useState(true);

  const [businessName, setBusinessName] = useState('');
  const handleBusinessNameChange = event => {
    setBusinessName(event.target.value);
  };

  const [fullName, setFullName] = useState('');
  const handleFullNameChange = event => {
    setFullName(event.target.value);
  };

  const [email, setEmail] = useState('');
  const handleEmailChange = event => {
    setEmail(event.target.value);
  };

  const [password, setPassword] = useState('');
  const handlePasswordChange = event => {
    setPassword(event.target.value);
  };

  const [phone, setPhone] = useState('');
  const handlePhoneChange = event => {
    setPhone(event.target.value);
  };

  const registerWithEmailAndPassword = async () => {
    if (!businessName) alert('Please enter business name');
    if (!fullName) alert('Please enter your full name');
    if (!email) alert('Please enter email');
    if (!password) alert('Please enter password');
    if (!phone) alert('Please enter phone number');
    try {
      const res = await createUserWithEmailAndPassword(auth, email, password);
      const user = res.user;
      const guid = 'CLT-' + uuidv4();
      const data = {
        uid: user.uid,
        'businessName': businessName,
        guid,
        "name" : fullName,
        authProvider: 'local',
        email,
        'phone': phone
      }
      dispatch(login(data));
      await addDoc(collection(db, 'clients'), data);
      setDispatching(false)
    } catch (err) {
      console.error(err);
      alert(err.message);
    }
  };

  const [user, loading] = useAuthState(auth);

  useEffect(() => {
    if (loading || dispatching) return;
    if (user) navigate();
  }, [user, loading, dispatching, navigate,]);

  const navigate = () => {
    history.push('/admin/dashboard')
  }

  return (
    <Flex
      direction="column"
      alignSelf="center"
      justifySelf="center"
      overflow="hidden"
    >
      <Box
        position="absolute"
        minH={{ base: '70vh', md: '50vh' }}
        w={{ md: 'calc(100vw - 50px)' }}
        borderRadius={{ md: '15px' }}
        left="0"
        right="0"
        bgRepeat="no-repeat"
        overflow="hidden"
        zIndex="-1"
        top="0"
        // bgImage={BgRegister}
        bgSize="cover"
        mx={{ md: 'auto' }}
        mt={{ md: '14px' }}
      ></Box>
      <Flex
        direction="column"
        textAlign="center"
        justifyContent="center"
        align="center"
        mt="6.5rem"
        mb="30px"
      >
        <Text fontSize="4xl" color="teal.300" fontWeight="bold">
          Welcome to Priorifi
        </Text>
        <Text
          fontSize="md"
          color="white"
          fontWeight="bold"
          mt="10px"
          mb="26px"
          w={{ base: '90%', sm: '60%', lg: '40%', xl: '30%' }}
        >
          Register below to get started
        </Text>
      </Flex>
      <Flex
        direction="column"
        alignSelf="center"
        background="transparent"
        borderRadius="15px"
        p="40px"
        // mx={{ base: "100px" }}
        bg={bgColor}
        w={{ base: '50%', lg: '50%', sm: '100%' }}
        boxShadow="0 20px 27px 0 rgb(0 0 0 / 5%)"
      >
        <FormControl>
          <FormLabel color="white" ms="4px" fontSize="md" fontWeight="bold">
            Business Name
          </FormLabel>
          <Input
            fontSize="md"
            ms="4px"
            type="text"
            placeholder="Your business name"
            mb="24px"
            value={businessName}
            onChange={handleBusinessNameChange}
            size="lg"
            color="white"
            borderRadius={30}
            borderColor="silver"
            fontWeight="bold"
            _placeholder={{ fontWeight: 'bold', color: 'silver', opacity: 0.5 }}
            _hover={{ borderColor: 'teal.300' }}
            focusBorderColor="teal.300"
          />
          <FormLabel color="white" ms="4px" fontSize="md" fontWeight="bold">
            Full Name
          </FormLabel>
          <Input
            fontSize="md"
            ms="4px"
            type="email"
            color="white"
            placeholder="Your full name"
            mb="24px"
            size="lg"
            value={fullName}
            onChange={handleFullNameChange}
            borderRadius={30}
            borderColor="silver"
            fontWeight="bold"
            _placeholder={{ fontWeight: 'bold', color: 'silver', opacity: 0.5 }}
            _hover={{ borderColor: 'teal.300' }}
            focusBorderColor="teal.300"
          />
          <FormLabel color="white" ms="4px" fontSize="md" fontWeight="bold">
            Email Address
          </FormLabel>
          <Input
            fontSize="md"
            ms="4px"
            type="email"
            color="white"
            placeholder="you@yourdomain.com"
            mb="24px"
            value={email}
            onChange={handleEmailChange}
            size="lg"
            borderRadius={30}
            borderColor="silver"
            fontWeight="bold"
            _placeholder={{ fontWeight: 'bold', color: 'silver', opacity: 0.5 }}
            _hover={{ borderColor: 'teal.300' }}
            focusBorderColor="teal.300"
          />
          <FormLabel color="white" ms="4px" fontSize="md" fontWeight="bold">
            Password
          </FormLabel>
          <InputGroup>
            <Input
              fontSize="md"
              ms="4px"
              type={show ? 'text' : 'password'}
              size="lg"
              color="white"
              value={password}
              onChange={handlePasswordChange}
              placeholder="Password"
              mb="24px"
              borderRadius={30}
              borderColor="silver"
              fontWeight="bold"
              _placeholder={{
                fontWeight: 'bold',
                color: 'silver',
                opacity: 0.5,
              }}
              _hover={{ borderColor: 'teal.300' }}
              focusBorderColor="teal.300"
            />
            <InputRightElement width="4.5rem">
              <Button
                marginTop="6px"
                h="1.75rem"
                size="sm"
                onClick={handleClick}
              >
                {show ? <ViewIcon /> : <ViewOffIcon />}
              </Button>
            </InputRightElement>
          </InputGroup>
          <FormLabel color="white" fontWeight="bold">
            Country
          </FormLabel>
          <Select
            fontSize="md"
            ms="4px"
            mb="24px"
            size="lg"
            borderRadius={30}
            borderColor="silver"
            fontWeight="bold"
            marginBottom="20px"
            color="white"
            _hover={{ borderColor: 'teal.300' }}
            focusBorderColor="teal.300"
          >
            <option value="option1">United States</option>
            <option value="option2">Australia</option>
            <option value="option3">China</option>
          </Select>
          <FormLabel color="white" ms="4px" fontSize="md" fontWeight="bold">
            Phone Number
          </FormLabel>
          <Input
            fontSize="md"
            ms="4px"
            type="phone"
            placeholder="Phone Number"
            mb="24px"
            color="white"
            value={phone}
            onChange={handlePhoneChange}
            size="lg"
            borderRadius={30}
            borderColor="silver"
            fontWeight="bold"
            _placeholder={{ fontWeight: 'bold', color: 'silver', opacity: 0.5 }}
            _hover={{ borderColor: 'teal.300' }}
            focusBorderColor="teal.300"
          />
          <FormControl display="flex" alignItems="center" mb="24px">
            <Switch id="remember-login" colorScheme="teal" me="10px" />
            <FormLabel
              color="white"
              htmlFor="remember-login"
              mb="0"
              fontWeight="normal"
            >
              Remember me
            </FormLabel>
          </FormControl>

          <Stack spacing={5} direction="row">
            <Checkbox color="white">
              I agree to the{' '}
              <Link color="teal.300" href="/privacy">
                Privacy Policy
              </Link>
            </Checkbox>
          </Stack>
          <Stack spacing={10} direction="row" marginBottom="20px">
            <Checkbox color="white">
              {' '}
              I agree to the{' '}
              <Link color="teal.300" href="/terms">
                Terms of Service
              </Link>
            </Checkbox>
          </Stack>
          <Button
            type="submit"
            bg="white"
            fontSize="15px"
            w="100%"
            h="45"
            mb="24px"
            color="black"
            onClick={() => registerWithEmailAndPassword()}
            fontWeight="bold"
            borderRadius={30}
            _hover={{ bg: 'silver' }}
            _active={{
              bg: 'teal.300',
            }}
          >
            Sign Up
          </Button>
        </FormControl>
        <Flex
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          maxW="100%"
          mt="0px"
        >
          <Text color={textColor} fontWeight="medium">
            Already have an account?
            <Link color="teal.300" href="/auth/login" marginLeft="5px">
              Login
            </Link>
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
}

export default Register;
