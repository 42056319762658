// Chakra imports
import {
    Box,
    Button,
    Flex,
    HStack,
    Link,
    Text,
    useColorModeValue,
    Image,
  } from "@chakra-ui/react";
  import {
    CreativeTimLogo,
    DocumentIcon,
    HomeIcon,
    PersonIcon,
    RocketIcon,
  } from "components/Icons/Icons";
  import SidebarResponsive from "components/Sidebar/SidebarResponsive";
  import PropTypes from "prop-types";
  import React from "react";
  import { NavLink } from "react-router-dom";
  import routes from "routes.js";
  export default function CampaignNavbar(props) {
    const [open, setOpen] = React.useState(false);
    const handleDrawerToggle = () => {
      setOpen(!open);
    };
    const { logo, logoText, secondary, ...rest } = props;
    // verifies if routeName is the one active (in browser input)
    const activeRoute = (routeName) => {
      return window.location.href.indexOf(routeName) > -1 ? true : false;
    };
    // Chakra color mode
    let navbarIcon = useColorModeValue("white", "gray.200");
    let mainText = useColorModeValue("gray.700", "gray.200");
    let navbarBg = useColorModeValue("transparent", "transparent");
    let navbarBorder = useColorModeValue(
      "1.5px solid #FFFFFF",
      "1.5px solid rgba(255, 255, 255, 0.31)"
    );
    let navbarShadow = useColorModeValue(
      "0px 7px 23px rgba(0, 0, 0, 0.05)",
      "none"
    );
    let navbarFilter = useColorModeValue(
      "none",
      "drop-shadow(0px 7px 23px rgba(0, 0, 0, 0.05))"
    );
    let navbarBackdrop = "blur(21px)";
    let bgButton = useColorModeValue(
      "linear-gradient(81.62deg, #313860 2.25%, #151928 79.87%)",
      "gray.800"
    );
    let navbarPosition = "fixed";
    let colorButton = "white";
    if (props.secondary === true) {
      navbarIcon = "white";
      navbarBg = "none";
      navbarBorder = "none";
      navbarShadow = "initial";
      navbarFilter = "initial";
      navbarBackdrop = "none";
      bgButton = "white";
      colorButton = "gray.700";
      mainText = "white";
      navbarPosition = "absolute";
    }
    var brand = (
      <Link
        href={`${process.env.PUBLIC_URL}/#/`}
        target="_blank"
        display="flex"
        lineHeight="100%"
        fontWeight="bold"
        justifyContent="center"
        alignItems="center"
        color={mainText}
      >
        <Image marginLeft="25px" src='https://i.imgur.com/EAwiqI6.png' w="35px" h="32px" me="10px" />
      </Link>
    );
    var linksAuth = (
      <HStack display={{ sm: "none", lg: "flex" }} marginLeft='100px'>
        <NavLink to="#">
          <Button
            fontSize="md"
            fontWeight="bold"
            ms="0px"
            me="0px"
            px="0px"
            me={{ sm: "2px", md: "16px" }}
            color={navbarIcon}
            variant="transparent-with-icon"
            _hover={{ color: "#0099d3" }}
          >
            <Text>Features</Text>
          </Button>
        </NavLink>
        <NavLink to="#">
          <Button
            fontSize="md"
            fontWeight="bold"
            ms="0px"
            me="0px"
            px="0px"
            me={{ sm: "2px", md: "16px" }}
            color={navbarIcon}
            variant="transparent-with-icon"
            _hover={{ color: "#0099d3" }}
          >
            <Text>About us</Text>
          </Button>
        </NavLink>
        <NavLink to="#">
          <Button
            fontSize="md"
            fontWeight="bold"
            ms="0px"
            me="0px"
            px="0px"
            me={{ sm: "2px", md: "16px" }}
            color={navbarIcon}
            variant="transparent-with-icon"
            _hover={{ color: "#0099d3" }}
          >
            <Text>How it works</Text>
          </Button>
        </NavLink>
        <NavLink to="#">
          <Button
            fontSize="md"
            fontWeight="bold"
            ms="0px"
            px="0px"
            me={{ sm: "2px", md: "16px" }}
            color={navbarIcon}
            variant="transparent-with-icon"
            _hover={{ color: "#0099d3" }}
          >
            <Text>Login</Text>
          </Button>
        </NavLink>
      </HStack>
    );
    return (
      <Flex
        position={navbarPosition}
        top="0"
        left="50%"
        transform="translate(-50%, 0px)"
        background={navbarBg}
        boxShadow={navbarShadow}
        filter={navbarFilter}
        backdropFilter={navbarBackdrop}
        borderRadius="15px"
        px="16px"
        py="22px"
        mx="auto"
        width="100%"
        maxW="100%"
        alignItems="center"
      >
        <Flex w="100%" justifyContent={{ sm: "start", lg: "space-between" }}>
          {brand}
          <Box
            ms={{ base: "auto", lg: "0px" }}
            display={{ base: "flex", lg: "none" }}
          >
            <SidebarResponsive
              logoText={props.logoText}
              secondary={props.secondary}
              routes={routes}
              // logo={logo}
              {...rest}
            />
          </Box>
          {linksAuth}
          <NavLink to={window.location.pathname + "#"}>
            <Button
              bg='white'
              color={'black'}
              bg={"#0099d3"}
              borderRadius="35px"
              marginRight="25px"
              fontWeight="bold"
              fontSize='15px'
              _hover={{ bg: "#52c5f0" }}
              px="30px"
              display={{
                sm: "none",
                lg: "flex",
              }}
            >
              Donate
            </Button>
          </NavLink>
        </Flex>
      </Flex>
    );
  }
  
  CampaignNavbar.propTypes = {
    color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"]),
    brandText: PropTypes.string,
  };
  